<template>
  <!-- 轨迹异常 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="姓名：">
          <el-input maxlength="15" placeholder="请输入姓名" v-model="queryForm.inspector_name"
            @input="(e) => (queryForm.inspector_name = validSpecialChar(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="告警类型：">
          <el-select popper-class="my-select" clearable v-model="queryForm.alarm_type" placeholder="请选择告警类型"
            @change="queryForm.alarm_type = queryForm.alarm_type ? queryForm.alarm_type : null; onSubmit()">
            <el-option label="静止超过10分钟" :value="1"></el-option>
            <el-option label="静止超过30分钟" :value="2"></el-option>
            <el-option label="静止超过60分钟" :value="3"></el-option>
            <el-option label="区域越界超过300米" :value="4"></el-option>
            <el-option label="区域越界超过500米" :value="5"></el-option>
            <el-option label="区域越界超过1000米" :value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="告警时间：">
          <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
            start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click=" onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="轨迹异常">
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="alarm_type">
              <span>{{ getAlarmTypeName(row.alarm_type) }}</span>
            </template>
            <template slot-scope="{row}" slot="alarm_time">
              <span>{{ validDateTime(row.alarm_time) }}</span>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click=" lookingfor(row)"></lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data=" tableData " align="center" header-align="center"
            tooltip-effect="dark" border stripe>
            <el-table-column prop="inspector_name" label="姓名" width="220"></el-table-column>
            <el-table-column prop="address" label="位置" min-width="250"></el-table-column>
            <el-table-column label="告警类型">
              <template slot-scope="scope">
                <span>{{ getAlarmTypeName(scope.row.alarm_type) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="alarm_time" label="告警时间" width="280">
              <template slot-scope="scope">
                <span>{{ validDateTime(scope.row.alarm_time) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="220">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click=" lookingfor(scope.row) "></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change=" handleSizeChange " @current-change=" handleCurrentChange "
            :current-page=" queryForm.PageIndex " :page-sizes=" [10, 20, 30, 40] " :page-size=" queryForm.PageSize "
            layout="total, sizes, prev, pager, next, jumper" :total=" TotalCount ">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <el-dialog :close-on-click-modal="true" append-to-body :visible.sync="centerDialogVisible" width="45%">
      <div class="map-container" id="map">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { getTrackAlarmList, getTrackAlarmDetail } from '@/api/administrative'
import { mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        alarm_type: null,
        inspector_name: '',
        start_time: '',
        end_time: '',
        PageSize: 10,
        PageIndex: 1
      },
      timeduan: '',
      // 表格数据
      tableData: [],
      TotalCount: 0,
      centerDialogVisible: false,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '姓名',
            prop: 'inspector_name'
          }, {
            label: '位置',
            prop: 'address'
          }, {
            label: '告警类型',
            prop: 'alarm_type',
            slot: true
          }, {
            label: '告警时间',
            prop: 'alarm_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName']),
    ...mapState(['parkingList'])
  },
  created () {
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  mounted () {
    this.fnGetTrackAlarmList()
  },
  methods: {
    async fnGetTrackAlarmList () {
      const res = await getTrackAlarmList({
        alarm_type: this.queryForm.alarm_type,
        inspector_name: this.queryForm.inspector_name,
        start_time: this.queryForm.start_time !== '' ? this.queryForm.start_time : '0001-01-01 00:00:00',
        end_time: this.queryForm.end_time !== '' ? this.queryForm.end_time : '0001-01-01 00:00:00',
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      console.log(res)
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
      } else {
        this.tableData = []
        this.TotalCount = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetTrackAlarmList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.fnGetTrackAlarmList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetTrackAlarmList()
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.fnGetTrackAlarmList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetTrackAlarmList()
    // },
    getAlarmTypeName (type) {
      switch (type) {
        case 1:
          return '静止超过10分钟'
        case 2:
          return '静止超过30分钟'
        case 3:
          return '静止超过60分钟'
        case 4:
          return '区域越界超过300米'
        case 5:
          return '区域越界超过500米'
        case 6:
          return '区域越界超过1000米'
      }
    },
    // 查看详情
    async lookingfor (row) {
      console.log('row====', JSON.stringify(row))
      const res = await getTrackAlarmDetail({ id: row._id })
      console.log('-=-=-=-=res=', JSON.stringify(res))
      if (res.Code !== 200) return
      this.centerDialogVisible = true
      setTimeout(() => {
        this.initMap(res.Data)
      }, 200)
    },
    initMap (data) {
      const that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        // 1.创建地图实例
        var map = new AMap.Map('map', {
          center: [data.longitude, data.latitude],
          zoom: 18,
          features: ['bg', 'road', 'building', 'point'] // , "point" 地标太多了显得乱
        })
        console.log('-=-=-=-=data=', JSON.stringify(data))
        var path = data.regional_location.map(element => element.split(';'))
        var polygon = new AMap.Polygon({
          path: path,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 60
        })
        polygon.setMap(map)

        var marker = new AMap.Marker({
          position: [data.longitude, data.latitude],
          content: `<img style="width:64px;height:64px;object-fit: contain;"  src="${require('@/assets/img/inspect_person.png')}"></img>`,
          offset: new AMap.Pixel(-30, -64),
          // autoRotation: true,
          angle: 0,
          zIndex: 70,
          map: map
        })
        // 监听marker的点击事件
        AMap.event.addListener(marker, 'click', function (e) {
          map.setCenter(marker.getPosition())
        })
        map.setFitView([marker, polygon])
      })
    }
  }
}
</script>

<style scoped lang="less">
.el-dialog__wrapper {
  /deep/.el-dialog {
    .el-dialog__header {
      height: 0 !important;
      padding: 0 !important;

      .el-dialog__headerbtn {
        right: 5px !important;
        top: 5px !important;
        width: 30px !important;
        height: 30px !important;
        background-color: rgba(0, 0, 0, 0.8) !important;
        border-radius: 15px !important;
        overflow: hidden !important;
        z-index: 9999 !important;
      }
    }

    .el-dialog__body {
      height: 100%;
      padding: 0 !important;
    }
  }
}

.map-container {
  position: relative;
  width: 100%;
  height: 600px;
}
</style>
